import React, { useCallback } from 'react';
import {Add16, Rename16, Copy16, Trash16, Edit16  } from '@weave-design/icons';
import { Benchmark, DataPointType, Factor, Metric } from '../../../../types/metrics';
import { newDataPointId } from '../../../utils/dataPointsUtils';
import Box from '@weave-mui/box';
import TooltipIcon from './TooltipIcon';
import i18n from '../../../../i18n';

interface ActionIconsProps {
  onAdd: () => void;
  onCopy: () => void;
  onRename: () => void;
  onDelete: () => void;
  onEdit: () => void;
  selectedDataPoint?: Metric | Factor | Benchmark;
  isAddEnabled: boolean;
  isReadOnly: boolean;
}



const ActionIcons: React.FC<ActionIconsProps> = ({
  onAdd, onCopy, onDelete, onRename, onEdit ,selectedDataPoint, isAddEnabled, isReadOnly
}) => {

  const isActionAllowed = selectedDataPoint?.type === DataPointType.Metric ||
    selectedDataPoint?.type === DataPointType.Factor ||
    selectedDataPoint?.type === DataPointType.SimulationParameter
    ? !(selectedDataPoint as Metric | Factor)?.isGlobal
    : true;
  const isFactor = selectedDataPoint?.type === DataPointType.Factor;
  const isDefaultSimulationFactor = selectedDataPoint?.type === DataPointType.SimulationParameter && (selectedDataPoint as Factor)?.isGlobal;
  const isEmpty = selectedDataPoint?.id === '';
  const isNewDataPointSelected = selectedDataPoint && selectedDataPoint.id === newDataPointId;
  const isCustomDataPoint = !isNewDataPointSelected && isActionAllowed;
  const isAddIconDisabled = !isAddEnabled || !isReadOnly;
  const isCopyIconDisabled= isDefaultSimulationFactor ? true : isNewDataPointSelected || !isReadOnly || isEmpty;
  const isRenameIconDisabled= isDefaultSimulationFactor ? true : (!(isActionAllowed && !isNewDataPointSelected) || !isReadOnly || isEmpty);
  const isDeleteIconDisabled= !((isCustomDataPoint && isReadOnly) || (isActionAllowed && isReadOnly) || isNewDataPointSelected) || isEmpty;
  const isEditIconDisabled = isDefaultSimulationFactor ? true : isFactor
    ? !isReadOnly || isNewDataPointSelected || isEmpty
    : !isActionAllowed || isNewDataPointSelected || !isReadOnly || isEmpty;

  const actionCb = useCallback((callback) => {
    if (isActionAllowed) {
      callback();
    }
  }, [selectedDataPoint, isActionAllowed]);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-evenly', marginBottom: '10px'}}>
      <TooltipIcon
        title={i18n.t('analysis.dataPoints.labels.icons.add')}
        isActionForbidden={isAddIconDisabled}
        icon={
          <Add16 data-testid='action-button-add'
            className={isAddIconDisabled && 'disabled-icon'}
            onClick={onAdd}
          />
        }
      />
      <TooltipIcon
        title={i18n.t('analysis.dataPoints.labels.icons.edit')}
        isActionForbidden={isEditIconDisabled}
        icon={
          <Edit16 data-testid='action-button-edit'
            className={isEditIconDisabled && 'disabled-icon'}
            onClick={onEdit}
          />
        }
      />
      <TooltipIcon
        title={i18n.t('analysis.dataPoints.labels.icons.copy')}
        isActionForbidden={isCopyIconDisabled}
        icon={
          <Copy16 data-testid='action-button-copy'
            className={isCopyIconDisabled && 'disabled-icon'}
            onClick={onCopy}
          />
        }
      />
      <TooltipIcon
        title={i18n.t('analysis.dataPoints.labels.icons.rename')}
        isActionForbidden={isRenameIconDisabled}
        icon={
          <Rename16 data-testid='action-button-rename'
            className={isRenameIconDisabled && 'disabled-icon'}
            onClick={() => actionCb(onRename)}
          />
        }

      />
      <TooltipIcon
        title={i18n.t('analysis.dataPoints.labels.icons.delete')}
        isActionForbidden={isDeleteIconDisabled}
        icon={
          <Trash16 data-testid='action-button-delete'
            className={isDeleteIconDisabled && 'disabled-icon'}
            onClick={() => actionCb(onDelete)}
          />
        }
      />
    </Box>
  );
}

export default ActionIcons
