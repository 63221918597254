import React, { useEffect, useState } from 'react';
import { LayoutTab, LayoutTabsConfig } from '../types/layout';
import { dashboardToLayoutTabsConfig } from './utils/layout-setup';
import { LayoutTabs } from './Tabs/LayoutTabs';
import { useGetDashboardsQuery } from '../state/api/dashboard-api';
import { LayoutContextProvider } from './LayoutContext';
import { useNavigate, useParams } from 'react-router-dom';

export const LayoutContainer: React.FC = () => {
  const navigate = useNavigate();
  const routeParams = useParams();
  const [layoutTabsConfig, setLayoutTabsConfig] = useState<LayoutTabsConfig>();
  const [initialActiveTab, setInitialActiveTab] = useState<LayoutTab | null>();
  // todo: resiliency handle failures and notify user
  const { data: dashboards } = useGetDashboardsQuery();
  // temoporary mock data
  // const [dashboards] = useState<StandardDashboard[]>(getMockStandardDashboards());
  useEffect(() => {
    if (dashboards?.length) {
      const tabsConfig = dashboardToLayoutTabsConfig(dashboards);
      setLayoutTabsConfig(tabsConfig);

      let tabToBeUsedAsInitial = null;
      const tabFromPath = routeParams.dashboardId && tabsConfig.tabs.find(( {value} ) => value === routeParams.dashboardId);
      if(tabFromPath) {
        tabToBeUsedAsInitial = tabFromPath;
      }

      setInitialActiveTab( tabToBeUsedAsInitial || tabsConfig.tabs[0] );
    }
  }, [dashboards, navigate, routeParams]);

  return (
    <>
      {layoutTabsConfig && initialActiveTab && (
        <LayoutContextProvider>
          <LayoutTabs {...layoutTabsConfig} initialActiveTab={initialActiveTab} ></LayoutTabs>
        </LayoutContextProvider>
      )}
    </>
  );
};
